import * as React from "react"
import { graphql } from "gatsby"

import { Container, Grid } from '@mui/material'

import Layout from "../components/layout"
import Seo from "../components/seo"
import ArticleCard from "../components/article-card"

const ArticlesPage = ({data}) => (
  <Layout>
    <Seo title="Articles" />
    <Container sx={{my: 4}}>
    <Grid container spacing={4}>
      {
        data.allMdx.edges.map((article) => {
          return (
          <Grid item xs={12} md={6} key={article.node.id}>
            <ArticleCard article={article.node} />
            </Grid>
          )})
      }
      </Grid>
      </Container>
  </Layout>
)

export const query = graphql`
  query {
    allMdx(sort: {fields: frontmatter___lastUpdatedDate, order: DESC}, filter: {frontmatter: {status: {eq: "published"}}}) {
        edges {
          node {
            frontmatter {
              title
              tags
              categories
              featuredImage {
                childImageSharp {
                  gatsbyImageData
                }
              }
              lastUpdatedDate(formatString: "MMMM D, YYYY")
            }
            id
            slug
            excerpt
            timeToRead
          }
        }
      }
  }
`

export default ArticlesPage